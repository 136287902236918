// import logo from './login.png';
import main from './main.png';
import ultramap from './ultramap.png';
import './App.css';

// import Carousel from './Carousel';

function App() {
  return (
    <div className="App">
      <div className="actions">
        <a className="actions-btn" type="button" href="https://cleanbetternow.com">
          Ir al Landing Page
        </a>
      </div>
      <header className="App-header">
        <img src={ultramap} alt="logo" className="ultra-cls" />
        <div className="container-cbn bounce">
          <div className="align-header bounce">
            <img src={main} alt="logo" className="sebas" />
          </div>
          <h2>The best solution for cleaning at your pace</h2>
          <p>
            Discover the top local cleaning providers in your community and delegate your tasks
            effortlessly.
          </p>
          {/* <img src={logo} alt="app" className="App-logo" />
        <img src={map} alt="app" className="App-logo" />
        <div className="carousel" ref={carouselRef}>
          <div className="carousel-track">
            {images.concat(images).map((image, index) => (
              <div className="carousel-slide" key={index}>
                <img src={image} alt={`Slide ${index}`} />
              </div>
            ))}
          </div>
        </div> */}
          {/* <Carousel /> */}
          <h1 className="title">BackOffice</h1>
        </div>
      </header>
    </div>
  );
}

export default App;
